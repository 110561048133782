"use strict";

/**
 * Created on July 24, 2020
 * @author Bryan Marvila
 */

(function () {
  'use strict';

  var generalLanguage = {
    open: 'Abrir',
    close: 'Fechar',
    yes: 'Sim',
    no: 'Não',
    cancel: 'Cancelar',
    erase: 'Apagar'
  };
  window.moduleExport({
    generalLanguage: generalLanguage
  }, typeof module !== 'undefined' && module);
})();